export enum AccessibilityColors {
  LIGHT = '#000',
  DARK = '#000',
  PRIMARY = '#000',
  INPUT_TEXT = '#FFF',
  CONTENT = '#000',
  CONTENT_BORDER = '#FFFF00',
  PRIMARY_LIGHT = '#C5CAE9',
  SECONDARY_CONTENT = '#FFFF00',
  SECONDARY = '#FFFF00',
  BUTTON_CONTRAST = '#000',
  SECONDARY_CONTRAST = '#FFF',
  DANGER = '#FF1229',
  WARNING = '#FFB400',
  SUCCESS = '#42CE95',
  INFO = '#14B6EF',
  PINK = '#ED0973',
}
