import React from 'react';

import { ColorsDefault } from '@styles/colors';

export const Save = () => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="20"
          fill="none"
          viewBox="0 0 17 20"
        >
          <path
            fill="#FFC107"
            d="M10.5 18c0 .5 0 1.5-.5 2H2c-1.11 0-2-.89-2-2V2a2 2 0 012-2h12a2 2 0 012 2v9.09c-.33-.05-.66-.09-1-.09-.34 0-.67.04-1 .09V2H9v8L6.5 7.75 4 10V2H2v16h8.5z"
          ></path>
          <path
            fill="#FFC107"
            d="M12.5 11c.084 0 .165.024.236.07a.464.464 0 01.166.19l1.161 2.431 2.552.388c.083.013.16.05.225.106a.494.494 0 01.024.698l-1.857 1.897.418 2.673a.491.491 0 01-.03.257.468.468 0 01-.152.202.436.436 0 01-.473.033l-2.27-1.26-2.27 1.26a.432.432 0 01-.473-.033.468.468 0 01-.153-.202.492.492 0 01-.028-.257l.417-2.673-1.857-1.897a.492.492 0 01.024-.698.441.441 0 01.225-.106l2.552-.388 1.16-2.43a.464.464 0 01.167-.19A.434.434 0 0112.5 11zm0 1.525l-.863 1.807a.467.467 0 01-.139.172.44.44 0 01-.199.084l-1.882.287 1.372 1.4a.476.476 0 01.13.415l-.309 1.977 1.679-.933a.433.433 0 01.422 0l1.679.933-.31-1.978a.493.493 0 01.13-.413l1.373-1.401-1.882-.287a.44.44 0 01-.199-.084.466.466 0 01-.139-.171l-.863-1.808z"
          ></path>
        </svg>
      );
};

export default Save;
