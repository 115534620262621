export enum ColorsDefault {
  LIGHT = '#221F52',
  DARK = '#000000',
  PRIMARY = '#EFF0F6',
  INPUT_TEXT = '#EFF0F6',
  CONTENT = '#C5CAE9',
  CONTENT_BORDER = '#221F52',
  PRIMARY_LIGHT = '#C5CAE9',
  SECONDARY_CONTENT = '#EFF0F6',
  SECONDARY = '#221F52',
  BUTTON_CONTRAST = '#FFFFFF',
  SECONDARY_CONTRAST = '#221F52',
  DANGER = '#FF1229',
  WARNING = '#FFB400',
  SUCCESS = '#42CE95',
  INFO = '#14B6EF',
  PINK = '#ED0973',
}
