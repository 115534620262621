import React from 'react';

import { ColorsDefault } from '@styles/colors';

export const SaveDbIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="23"
      fill="none"
      viewBox="0 0 21 23"
    >
      <path
        fill="#2C3382"
        d="M9.09 18c.12.72.37 1.39.72 2H2c-1.11 0-2-.89-2-2V2a2 2 0 012-2h12a2 2 0 012 2v9.09c-.33-.05-.66-.09-1-.09-.34 0-.67.04-1 .09V2H9v8L6.5 7.75 4 10V2H2v16h7.09z"
      ></path>
      <path
        fill="#2C3382"
        d="M10.817 20H9.27l.01-1.143h1.537c.384 0 .708-.086.972-.258.264-.176.462-.432.596-.767.136-.335.205-.74.205-1.216v-.346c0-.365-.04-.686-.117-.962a1.912 1.912 0 00-.337-.699 1.409 1.409 0 00-.552-.424 1.823 1.823 0 00-.752-.147H9.24v-1.147h1.592c.475 0 .91.081 1.304.244.397.16.74.389 1.03.688.29.3.513.658.669 1.075.16.413.24.873.24 1.381v.337c0 .505-.08.965-.24 1.382a3.08 3.08 0 01-.669 1.074c-.286.297-.63.526-1.03.689-.397.16-.837.239-1.319.239zm-.717-7.11V20H8.635v-7.11H10.1zm7.822 3.99h-1.826l-.01-1.006h1.533c.27 0 .492-.034.664-.103a.804.804 0 00.386-.307.902.902 0 00.132-.498c0-.221-.042-.4-.127-.537a.697.697 0 00-.386-.298 2.016 2.016 0 00-.669-.093h-1.015V20h-1.465v-7.11h2.48c.414 0 .783.04 1.108.118.33.078.608.197.835.356.228.16.402.362.523.606.12.24.18.527.18.86 0 .292-.066.562-.2.81-.13.247-.337.449-.62.605-.28.156-.646.243-1.098.259l-.425.376zM17.858 20h-2.163l.572-1.143h1.591c.258 0 .468-.04.63-.122a.837.837 0 00.362-.341c.078-.144.117-.308.117-.494 0-.208-.036-.389-.108-.542a.725.725 0 00-.332-.351c-.153-.085-.355-.127-.605-.127H16.51l.01-1.006h1.757l.337.396c.433-.007.782.07 1.045.229.267.156.46.36.581.61.124.251.186.52.186.806 0 .456-.1.84-.298 1.152-.199.31-.49.542-.874.699-.381.156-.846.234-1.397.234z"
      ></path>
    </svg>
  );
};

export default SaveDbIcon;
